<template>
  <div>
    <Login v-if="!isLogin" @onSubmit="handleSubmit"></Login>
    <div class="content bg" v-else>
      <dv-full-screen-container>
        <ModuleTitle
          :branchList="branchList"
          @changeBranchId="change"
          @handleOut="handleOut"
          @handleEnter="handleEnter"
          :branchName="branchName"
          :showSelect="showSelect"
        />
        <div class="marginLR">
          <div style="flex: 1">
            <ModuleHeaderRight
              :data="contract2"
              :nameFee="nameFee"
              :contract3="contract3"
              @changeLeft="changeLeft"
            ></ModuleHeaderRight>
          </div>
        </div>
        <ModuleMain
          :contract3="contract3"
          :zjsRanking="zjsRanking"
          :ranking="ranking"
          :source_ranking="source_ranking"
          :contract_list="contract_list"
          @changeTime="handleTime"
          @changeSourceTime="handleSourceTime"
          @changeT="handleT"
          :dayData="dayData"
          :guest_list="guest_list"
          :branchId="branchId"
        />
      </dv-full-screen-container>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "./ModuleTitle.vue";
import ModuleHeaderRight from "./ModuleHeaderRight.vue";
import ModuleMain from "./ModuleMain.vue";
import Login from "./Login.vue";
import { Message } from "element-ui";

export default {
  name: "HomeView",
  components: {
    ModuleTitle,
    ModuleHeaderRight,
    ModuleMain,
    Login,
  },
  data() {
    return {
      isLogin: true,
      ws: null,
      disconnectTimer: null,
      data: {},
      loginData: {},
      announcement: "",
      token: "",
      contract1: [],
      contract2: {
        dates: [],
      },
      nameFee: {},
      contract3: {},
      dayData: {},
      contract4: {},
      branchList: [],
      branchId: "",
      branchName: "",
      showSelect: false,
      branchOldId: "",
      ranking: [],
      contract_list: [],
      rankingLoading: false,
      source_ranking: [],
      zjsRanking: [],
      guest_list: [],
      time: {
        begin_at: "",
        end_at: "",
      },
      sourceTime: {
        begin_at: "",
        end_at: "",
      },
      radioType: null,
    };
  },
  mounted() {
    this.initWebSocket();
  },
  beforeDestroy() {
    this.ws.close();
  },

  methods: {
    initWebSocket() {
      if (typeof WebSocket === "undefined") {
        Message.error("您的浏览器不支持WebSocket，无法获取数据");
        return false;
      }
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }

      //this.ws = new WebSocket("ws://127.0.0.1:9091/network/ws");
      this.ws = new WebSocket("ws://data.ssqc.cn/network/ws"); // 使用 WebSocket 连接到服务器
      this.ws.onopen = () => {
        console.log("WebSocket 已连接");
        const name = localStorage.getItem("userName");
        const password = localStorage.getItem("password");

        let data = {
          type: "login",
          username: name,
          password: password,
        };
        if (name) {
          this.ws.send(JSON.stringify(data));
        }
      };
      this.ws.onmessage = (event) => {
        let res = JSON.parse(event.data);
        if (res.code) {
          Message.error(res.message);
          return false;
        }
        if (res.type == "announcement") {
          this.announcement = res.data.msg;
          this.openHTML();
        }
        if (res.type === "login" && res.data.token) {
          this.isLogin = true;
          this.token = res.data.token;
          res.data.branch_list.unshift({ name: "总计", id: 0 });
          this.branchList = res.data.branch_list;
          this.branchId = res.data.branch_id;
          if (res.data.branch_id === 0) {
            this.showSelect = true;
            this.branchName = "";
          } else {
            this.showSelect = false;
            const find = res.data.branch_list.find(
              (_) => _.id === res.data.branch_id
            );
            this.branchName = find.name;
          }
        }

        if (res.type === "contract2" && res.data?.groups) {
          this.contract2 = res.data;
        }
        if (res.type === "guest_name_fee_state") {
          this.nameFee = res.data;
        }
        if (res.type === "ranking") {
          this.ranking = res.data || [];
        }
        if (res.type === "contract3" && res.data?.monthData) {
          this.contract3 = res.data;
          this.dayData = res.data?.dayData || {};
        }
        if (res.type === "contract_list") {
          this.contract_list = res.data || [];
        }
        if (res.type === "source_ranking") {
          this.source_ranking = res.data || [];
        }
        if (res.type === "zjsRanking") {
          this.zjsRanking = res.data || [];
        }
        if (res.type === "guest_list") {
          this.guest_list = res.data || [];
        }
      };
      //
      this.ws.onclose = () => {
        console.log("WebSocket 已关闭");
        let that = this;
        if (!this.disconnectTimer) {
          this.disconnectTimer = setTimeout(() => {
            that.initWebSocket();
            that.disconnectTimer = null;
          }, 4000);
        }
      };
      this.ws.onerror = () => {
        console.error("WebSocket 连接错误");
      };
    },

    handleEnter() {
      const name = localStorage.getItem("userName");
      const password = localStorage.getItem("password");
      window.location.href = `http://gl.ssqc.cn/#/login?username=${name}&password=${password}`;
 

      // window.location.href = `http://localhost:5173/#/login?username=admin&password=admin`;

      
    },
    handleOut() {
      localStorage.clear();
      this.isLogin = false;
      this.ws.close();
    },
    changeLeft(lastDate) {
      let contract2 = {
        type: "contract2",
        end_at: lastDate,
        token: this.token,
        branch_id: this.branchId,
      };

      this.ws.send(JSON.stringify(contract2));
    },
    handleT(value) {
      if (value == "5") {
        let guest_list = {
          type: "guest_list",
          token: this.token,
          branch_id: this.branchId || 0,
        };
        this.ws.send(JSON.stringify(guest_list));
      } else {
        let t = {
          t: value,
          type: "contract_list",
          token: this.token,
          branch_id: this.branchId || 0,
        };
        this.ws.send(JSON.stringify(t));
      }
    },
    handleSourceTime(value) {
      this.sourceTime = { ...value };
      let source_ranking = {
        ...value,
        type: "source_ranking",
        token: this.token,
        branch_id: this.branchId || 0,
      };
      this.ws.send(JSON.stringify(source_ranking));
    },
    handleTime(value, type) {
      this.time = { ...value };
      this.radioType = type;
      if (type == 3 || type == 4) {
        let zjsRanking = {
          ...value,
          type: "zjsRanking",
          token: this.token,
          branch_id: this.branchId || 0,
        };
        this.ws.send(JSON.stringify(zjsRanking));
        return false;
      }

      let ranking = {
        ...value,
        type: "ranking",
        token: this.token,
        branch_id: this.branchId || 0,
      };
      this.ws.send(JSON.stringify(ranking));
    },
    change(id) {
      this.branchId = id;
      let contract2 = {
        type: "contract2",
        token: this.token,
        branch_id: this.branchId,
      };

      this.ws.send(JSON.stringify(contract2));

      if (this.radioType == 3 || this.radioType == 4) {
        let zjsRanking = {
          ...this.time,
          type: "zjsRanking",
          token: this.token,
          branch_id: this.branchId || 0,
        };
        this.ws.send(JSON.stringify(zjsRanking));
      } else {
        let ranking = {
          ...this.time,
          type: "ranking",
          token: this.token,
          branch_id: this.branchId || 0,
        };
        this.ws.send(JSON.stringify(ranking));
      }
      let source_ranking = {
        ...this.time,
        type: "source_ranking",
        token: this.token,
        branch_id: this.branchId || 0,
      };
      this.ws.send(JSON.stringify(source_ranking));
      let contract_list = {
        type: "contract_list",
        token: this.token,
        branch_id: this.branchId || 0,
      };
      this.ws.send(JSON.stringify(contract_list));
      let contract3 = {
        type: "contract3",
        token: this.token,
        branch_id: this.branchId || 0,
      };
      this.ws.send(JSON.stringify(contract3));
      let guest_name_fee_state = {
        type: "guest_name_fee_state",
        token: this.token,
        branch_id: this.branchId || 0,
      };
      this.ws.send(JSON.stringify(guest_name_fee_state));
    },
    openHTML() {
      Message({
        dangerouslyUseHTMLString: true,
        type: "success",
        duration: 5000,
        // message: '<span>恭喜 <span >哈哈</span> 报单成功 🎉🎉🎉🎉🎉🎉</span>'
        message: this.announcement,
      });
    },
    handleSubmit(value) {
      let data = {
        type: "login",
        username: value.userName,
        password: value.password,
      };
      localStorage.setItem("userName", value.userName);
      localStorage.setItem("password", value.password);
      this.loginData = data;
      this.ws.send(JSON.stringify(data));
    },
  },
};
</script>
<style>
.title-logo {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  margin-left: 16px;
}

.marginLR {
  margin: 0 10px;
}
</style>
