<template>
  <div class="header-box">
    <div class="title-right">
      <dv-decoration-10 style="height: 5px" />
      <div class="title-date">{{ date }}</div>
    </div>
    <div class="d-flex">
      <dv-decoration-8 style="height: 2.8rem; width: 120px" />
      <div class="title-name">{{ branchName || "盛世启辰" }}数据分析</div>
      <dv-decoration-8 :reverse="true" style="height: 2.8rem; width: 120px" />
    </div>
    <div class="title-right">
      <dv-decoration-10 style="height: 5px; transform: rotateY(180deg)" />
      <div class="right-box">
        <el-select
          v-if="showSelect"
          class="select-box"
          v-model="branchId"
          filterable
          placeholder="请选择"
          @change="change"
        >
          <el-option
            v-for="item in branchList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div @click="handleEnter" class="out">进入系统</div>
        <div @click="handleOut" class="out">退出登录</div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  props: {
    branchList: {
      type: Array,
      default: [],
    },

    showSelect: {
      type: Boolean,
      default: false,
    },
    branchName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      date: "",
      branchId: "",
    };
  },
  mounted() {
    setInterval(() => {
      this.date = this.getNowDate();
    }, 1000);
  },
  methods: {
    // 格式化日对象
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      var weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日 " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds +
        " " +
        week
      );
    },
    handleOut() {
      this.$emit("handleOut");
    },
    handleEnter(){
      this.$emit("handleEnter"); 
    },
    change() {
      this.$emit("changeBranchId", this.branchId);
    },
  },
  watch: {

  },
};
</script>
<style>
.header-box {
  display: flex;
  color: #92a8c5;
}

.title-name {
  padding-top: 4px;
  font-size: 22px;
  margin: 0 4px;
  color: #92a8c5;
}

.title-right {
  flex: 1;
}

.select-box {
  margin-left: auto;
}

.right-box {
  margin-right: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title-date {
  line-height: 40px;
  font-size: 20px;
  font-weight: 800;
  margin-left: 10px;
}

.el-select .el-input__inner {
  background: #000;
  color: #dad7d7;
  height: 30px;
  line-height: 30px;
}

.el-scrollbar__wrap {
  background: #0d1023;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #26303f !important;
}

.d-flex {
  display: flex;
  text-align: center;
}

.out {
  margin-left: 10px;
}
</style>
