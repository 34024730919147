<template>
  <div class="row-box" style="width: 100%; height: 44vh">
    <div class="left-box" style="width: 75%; height: 44vh">
      <!-- <el-carousel
        height="44vh"
        :autoplay="false"
        trigger="click"
        :loop="true"
        @change="handleCar"
        arrow="always"
      >
        <el-carousel-item :key="1" >
          <dv-border-box-12   style="width: 100%; height: 44vh">
            <dv-loading v-if="dataLoading">Loading...</dv-loading>
            <div
              id="echart2-new"
              class="padding"
              style="width: 100%; height: 43vh"
            ></div>
          </dv-border-box-12>
        </el-carousel-item>
        <el-carousel-item :key="2">
          <dv-border-box-12 style="width: 100%; height: 44vh">
            <dv-loading v-if="nameFeeLoading">Loading...</dv-loading>
            <div id="nameFee" style="width: 100%; height: 45vh"></div>
          </dv-border-box-12>
        </el-carousel-item>
      </el-carousel> -->
      <div>
        <dv-border-box-12 style="width: 100%; height: 44vh">
          <div v-show="typeIndex == 0">
            <dv-loading v-if="dataLoading">Loading...</dv-loading>
            <div
              id="echart2-new"
              class="padding"
              style="width: 100%; height: 43vh"
            ></div>
          </div>
          <div v-show="typeIndex == 1">
            <dv-loading v-if="nameFeeLoading">Loading...</dv-loading>
            <div id="nameFee" style="width: 100%; height: 45vh"></div>
          </div>
        </dv-border-box-12>
        <div>
          <div class="arrow box-icon-left" @click="handleChangeBox">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="arrow box-icon-right" @click="handleChangeBox">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>

      <div v-if="typeIndex == 0">
        <div class="arrow icon-left" @click="handleLeft">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="arrow icon-right" @click="handleRight">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <dv-border-box-1 style="width: 25%; height: 44vh">
      <dv-loading v-if="contract3Loading">Loading...</dv-loading>
      <div class="echart4" v-if="contract3?.monthData">
        <div class="title-num">当月数/总数</div>
        <div class="content-box">
          <div class="row-flex">
            <div class="count-item">
              <span
                :style="
                  contract3.monthData.new_tally_count -
                    contract3.preData.new_tally_count >
                  0
                    ? 'color:#f00'
                    : 'color:#03fc62'
                "
              >
                <i
                  class="el-icon-top"
                  v-if="
                    contract3.monthData.new_tally_count -
                      contract3.preData.new_tally_count >
                    0
                  "
                ></i>
                <i class="el-icon-bottom" v-else></i>
                {{
                  contract3.monthData.new_tally_count -
                  contract3.preData.new_tally_count
                }}
              </span>
              <div class="header-count">
                {{ contract3.monthData.new_tally_count }}/{{
                  contract3.totalData.new_tally_count
                }}
              </div>
              <div class="header-text">新签代账</div>
            </div>
            <div class="count-item">
              <span
                :style="
                  contract3.monthData?.renew_tally_count -
                    contract3.preData?.renew_tally_count >
                  0
                    ? 'color:#f00'
                    : 'color:#03fc62'
                "
              >
                <i
                  class="el-icon-top"
                  v-if="
                    contract3.monthData.renew_tally_count -
                      contract3.preData.renew_tally_count >
                    0
                  "
                ></i>
                <i class="el-icon-bottom" v-else></i>
                {{
                  contract3.monthData.renew_tally_count -
                  contract3.preData.renew_tally_count
                }}
              </span>
              <div class="header-count">
                {{ contract3.monthData.renew_tally_count }}/{{
                  contract3.totalData.renew_tally_count
                }}
              </div>
              <div class="header-text">续签代账</div>
            </div>
          </div>
          <div class="row-flex">
            <div class="count-item">
              <span
                :style="
                  contract3.monthData.other_count -
                    contract3.preData.other_count >
                  0
                    ? 'color:#f00'
                    : 'color:#03fc62'
                "
              >
                <i
                  class="el-icon-top"
                  v-if="
                    contract3.monthData.other_count -
                      contract3.preData.other_count >
                    0
                  "
                ></i>
                <i class="el-icon-bottom" v-else></i>
                {{
                  contract3.monthData.other_count -
                  contract3.preData.other_count
                }}
              </span>
              <div class="header-count">
                {{ contract3.monthData.other_count }}/{{
                  contract3.totalData.other_count
                }}
              </div>
              <div class="header-text">其它</div>
            </div>
            <div class="count-item">
              <span
                :style="
                  contract3.monthData?.zjs_count -
                    contract3.preData?.zjs_count >
                  0
                    ? 'color:#f00'
                    : 'color:#03fc62'
                "
              >
                <i
                  class="el-icon-top"
                  v-if="
                    contract3.monthData.zjs_count -
                      contract3.preData.zjs_count >
                    0
                  "
                ></i>
                <i class="el-icon-bottom" v-else></i>
                {{
                  contract3.monthData.zjs_count - contract3.preData.zjs_count
                }}
              </span>
              <div class="header-count">
                {{ contract3.monthData.zjs_count }}/{{
                  contract3.totalData.zjs_count
                }}
              </div>
              <div class="header-text">转介绍</div>
            </div>
          </div>
          <div class="row-flex">
            <div class="count-item">
              <span
                :style="
                  contract3.monthData.seperated_count -
                    contract3.preData.seperated_count >
                  0
                    ? 'color:#f00'
                    : 'color:#03fc62'
                "
              >
                <i
                  class="el-icon-top"
                  v-if="
                    contract3.monthData.seperated_count -
                      contract3.preData.seperated_count >
                    0
                  "
                ></i>
                <i class="el-icon-bottom" v-else></i>
                {{
                  contract3.monthData.seperated_count -
                  contract3.preData.seperated_count
                }}
              </span>
              <div class="header-count">
                {{ contract3.monthData.seperated_count }}/{{
                  contract3.totalData.seperated_count
                }}
              </div>
              <div class="header-text">名单数量</div>
            </div>
            <div class="count-item">
              <span
                :style="
                  contract3.monthData.seperated_money -
                    contract3.preData.seperated_money >
                  0
                    ? 'color:#f00'
                    : 'color:#03fc62'
                "
              >
                <i
                  class="el-icon-top"
                  v-if="
                    contract3.monthData.seperated_money -
                      contract3.preData.seperated_money >
                    0
                  "
                ></i>
                <i class="el-icon-bottom" v-else></i>
                {{
                  contract3.monthData.seperated_money -
                  contract3.preData.seperated_money
                }}
              </span>
              <div class="header-count">
                ¥{{ contract3.monthData.seperated_money }}/¥{{
                  contract3.totalData.seperated_money
                }}
              </div>
              <div class="header-text">名单金额</div>
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>

<script>
import * as echarts from "echarts";
import util from "../utils/util.js";
import { Message } from "element-ui";

export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    contract3: {
      type: Object,
      default: {},
    },
    nameFee: {
      type: Object,
      default: {
        dates: [],
      },
    },
  },
  data() {
    return {
      // name: [],
      // series: [],
      // title: "",

      typeIndex: 0,

      dataLoading: true,
      nameFeeLoading: true,
      contract3Loading: true,
      chart: null,

      // companyName: [],
      // list: [
      //   "newTallyGroups",
      //   "otherGroups",
      //   "renewTallyGroups",
      //   "totalGroups",
      // ],
    };
  },

  mounted() {
    this.initChart();
  },
  methods: {
    handleChangeBox() {
      this.typeIndex = this.typeIndex == 0 ? 1 : 0;

      if (this.typeIndex == 1) {
        setTimeout(() => {
          this.nameFeeLoading = false;
          this.echartNameFee();
        }, 1000);
      }
    },
    initChart() {
      const chartDom = document.getElementById("echart2-new");
      chartDom.style.width = "100%";
      this.chart = echarts.init(chartDom);
      var option;
      option = {
        // color:['#e062ae','#9fe6b8','#96bfff','#32c5e9','#5470C6','#956FD4','#3EACE5',"#0184d5"],
        color: ["#e95f5f", "#e3bf6d", "#956FD4", "#0184d5", "#33ffff"],
        legend: {
          inactiveColor: "#485774",
          textStyle: {
            color: "#70b2f6",
            fontSize: 12,
          },
          selected: {
            新签代账数: true,
            续签数: false,
            其它数: false,
            新签数: false,

            新签代账金额: true,
            续签金额: false,
            其它金额: false,
            新签金额: false,
          },
        },
        tooltip: {},
        grid: {
          left: 50,
          right: 50,
          top: 80,
          bottom: 20,
          containLabel: true,
        },
        textStyle: {
          color: "#999",
        },
        xAxis: [
          {
            type: "category",
            data: [], //this.data.dates,
            axisLine: {
              lineStyle: {
                color: "#333",
              },
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#92a8c5",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              margin: 10,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#92a8c5",
            },
          },
          {
            type: "value",
            axisLabel: {
              margin: 10,
              color: "#92a8c5",
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#fff" },
                  { offset: 0, color: "#e95f5f" },
                ]),
              },
            },
            name: "新签代账数",
            type: "bar",
            data: [], // this.data.groups.new_tally_groups[0].data,
            emphasis: {
              focus: "series",
            },

            label: {
              show: true,
              position: "top",
              // position: [10, -10],
              fontSize: 12,
              fontWeight: "bold",
              color: "#92a8c5",
            },
          },
          {
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#fff" },
                  { offset: 0, color: "#e3bf6d" },
                ]),
              },
            },
            name: "续签数",
            type: "bar",
            data: [], // this.data.groups.renew_tally_groups[0].data,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              fontWeight: "bold",
              color: "#92a8c5",
            },
          },
          {
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#fff" },
                  { offset: 0, color: "#956FD4" },
                ]),
              },
            },
            name: "其它数",
            type: "bar",
            data: [], //this.data.groups.other_groups[0].data,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              fontWeight: "bold",
              color: "#92a8c5",
            },
          },
          {
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#fff" },
                  { offset: 0, color: "#0184d5" },
                ]),
              },
            },
            name: "新签数",
            type: "bar",
            data: [], // this.data.groups.new_groups[0].data,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              fontWeight: "bold",
              color: "#92a8c5",
            },
          },

          {
            name: "新签代账金额",
            symbolSize: 10,
            type: "line",
            yAxisIndex: 1,
            // smooth: true,
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ¥";
              },
            },
            label: {
              show: true,
              position: "bottom",
              // position: [24, 8],

              textStyle: {
                color: "#92a8c5",
                fontSize: 12,
                fontWeight: "bold",
                zIndex: 1,
              },
            },

            data: [], // this.data.groups.new_tally_money_groups[0].data,
          },
          {
            name: "续签金额",
            symbolSize: 10,
            type: "line",
            yAxisIndex: 1,
            // smooth: true,
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ¥";
              },
            },
            label: {
              show: true,
              position: "bottom",
              // position: [24, 8],

              textStyle: {
                color: "#92a8c5",
                fontSize: 12,
                fontWeight: "bold",
                zIndex: 1,
              },
            },
            data: [], //this.data.groups.renew_tally_money_groups[0].data,
          },
          {
            name: "其它金额",
            symbolSize: 10,
            type: "line",
            yAxisIndex: 1,
            // smooth: true,
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ¥";
              },
            },
            label: {
              show: true,
              position: "bottom",
              // position: [24, 8],
              textStyle: {
                color: "#92a8c5",
                fontSize: 12,
                fontWeight: "bold",
                zIndex: 1,
              },
            },
            data: [], // this.data.groups.other_money_groups[0].data,
          },
          {
            name: "新签金额",
            symbolSize: 10,
            type: "line",
            yAxisIndex: 1,
            // smooth: true,
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ¥";
              },
            },
            label: {
              show: true,
              position: "bottom",
              // position: [24, 8],
              textStyle: {
                color: "#92a8c5",
                fontSize: 12,
                fontWeight: "bold",
                zIndex: 1,
              },
            },
            data: [], // this.data.groups.new_money_groups[0].data,
          },
        ],
      };
      option && this.chart.setOption(option);
    },

    handleRight() {
      const date = this.data.dates.at(-1).split("-");
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从0开始，需要加1
      // console.log(year, month);
      if (year == date[0] && month == date[1]) {
        console.log(month, "month");
        Message.warning("当前已是最后月份！");
        return false;
      }

      let nextMonthDate = new Date(date[0], date[1], 1);
      let nextMonthYear = nextMonthDate.getFullYear();
      let nextMonthMonth = nextMonthDate.getMonth() + 1; // 月份从 0 开始计数，所以需要加 1
      if (nextMonthMonth.toString().length == 1) {
        nextMonthMonth = `0${nextMonthMonth}`;
      }
      const lastDay = util.getLastDayOfMonth(nextMonthYear, nextMonthMonth);
      const strDate = `${nextMonthYear}-${nextMonthMonth}-${lastDay}`;
      this.$emit("changeLeft", strDate);
    },
    handleLeft() {
      const date = this.data.dates.at(-2).split("-");
      date[1] = date[1].length == 2 ? date[1] : `0${date[1]}`;
      const lastDay = util.getLastDayOfMonth(date[0], date[1]);
      const strDate = `${date[0]}-${date[1]}-${lastDay}`;
      this.$emit("changeLeft", strDate);
    },

    echartNameFee() {
      const chartDom = document.getElementById("nameFee");
      const myChart = echarts.init(chartDom);
      var option;

      option = {
        // backgroundColor: '#061326',
        color: ["#344b8c", "#956FD4"],
        title: {
          text: "名单数量和金额",
          top: 10,
          left: 20,
          textStyle: {
            color: "#92a8c5",
            fontSize: 16,
          },
        },
        legend: {
          top: 10,
          inactiveColor: "#485774",
          textStyle: {
            color: "#70b2f6",
            fontSize: 12,
          },
        },
        tooltip: {},
        grid: {
          left: 60,
          right: 60,
          top: 80,
          bottom: 30,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#333",
              },
            },
            axisLabel: {
              margin: 25,
              interval: 0,
              textStyle: {
                color: "#92a8c5",
              },
            },
            data: this.nameFee.dates,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              margin: 20,
              textStyle: {
                color: "#92a8c5",
              },
            },

            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#92a8c5",
            },
          },
          {
            type: "value",
            axisLabel: {
              margin: 10,
              color: "#92a8c5",
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  // { offset: 1, color: '#fff' },
                  { offset: 0, color: "#344b8c" },
                ]),
              },
            },
            // name: "名单数量",
            type: "bar",
            name: "名单金额",
            data: this.nameFee.groups.money[0].data,
            // data: this.nameFee.groups.count[0].data,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              fontWeight: "bold",
              color: "#92a8c5",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ¥";
              },
            },
          },

          {
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  // { offset: 1, color: '#fff' },
                  // { offset: 0, color: '#0184d5' },
                  { offset: 0, color: "#956FD4" },
                  { offset: 1, color: "#3EACE5" },
                ]),
              },
            },
            // name: "资源签单数",
            name: "资源签单金额",
            type: "bar",
            data: this.data.groups?.source_money_groups[0].data,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              fontWeight: "bold",
              color: "#92a8c5",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ¥";
              },
            },
          },

          {
            name: "名单数量",
            data: this.nameFee.groups.count[0].data,

            symbolSize: 10,
            type: "line",
            yAxisIndex: 1,
            // symbol: 'circle',
            // smooth: true,
            emphasis: {
              focus: "series",
            },

            // itemStyle: {
            //   color: "#344b8c",
            //   borderColor: "#fff",

            // },
            label: {
              show: true,
              position: "top",
              textStyle: {
                fontSize: 14,
                fontWeight: "bold",
                color: "#92a8c5",
                zIndex: 13,
              },
            },
          },
          {
            name: "资源签单数",

            data: this.data.groups.source_groups[0].data,
            // name: "资源签单金额",
            symbolSize: 10,
            type: "line",
            yAxisIndex: 1,
            // smooth: true,
            emphasis: {
              focus: "series",
            },

            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#92a8c5",
                fontSize: 12,
                fontWeight: "bold",
                zIndex: 1,
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
  watch: {
    data(newVal, oldVal) {
      this.dataLoading = false;

      this.chart.setOption({
        xAxis: [
          {
            data: this.data.dates,
          },
        ],
        series: [
          {
            data: this.data.groups.new_tally_groups[0].data,
          },
          {
            data: this.data.groups.renew_tally_groups[0].data,
          },
          {
            data: this.data.groups.other_groups[0].data,
          },
          {
            data: this.data.groups.new_groups[0].data,
          },

          {
            data: this.data.groups.new_tally_money_groups[0].data,
          },
          {
            data: this.data.groups.renew_tally_money_groups[0].data,
          },
          {
            data: this.data.groups.other_money_groups[0].data,
          },
          {
            data: this.data.groups.new_money_groups[0].data,
          },
        ],
      });
    },
    nameFee(newVal, oldVal) {
      if (this.data.groups?.source_money_groups && this.typeIndex == 1) {
        this.nameFeeLoading = false;
        this.echartNameFee();
      }
    },
    contract3(newVal, oldVal) {
      this.contract3Loading = false;
    },
  },
};
</script>
<style scoped>
.row-box {
  display: flex;
}

.left-box {
  position: relative;
}

.title-num {
  text-align: center;
  color: #92a8c5;
}

.content-box {
  height: 40vh;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-top: 6px;
}

.row-flex {
  display: flex;
  margin-bottom: 20px;
}

.count-item {
  flex: 1;
  padding: 5px 0;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgb(52, 75, 140) 0px 0px 25px 3px inset;
  border-radius: 8px;
  color: #9297c5;
  font-size: 12px;
}

.arrow {
  height: 36px;
  width: 36px;
  cursor: pointer;
  border-radius: 50%;
  /* background-color: rgba(31, 45, 61, 0.31); */
  background-color: #3a8ee6;

  color: #fff;
  z-index: 9;
  text-align: center;
  line-height: 36px;
}

.icon-left {
  z-index: 9;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.icon-right {
  z-index: 9;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.box-icon-left {
  z-index: 9;
  position: absolute;
  top: 200px;
  left: 20px;
}
.box-icon-right {
  z-index: 9;
  position: absolute;
  top: 200px;
  right: 20px;
}
</style>
