<template>
  <div class="page-box">
    <img src="../assets/img/bg1.png" alt="" class="bg-img">
    <div class="right">
      <div class="title">
        <!-- <img class="title-logo" src="../assets/img/log.jpeg" alt="" /> -->
        <h2>数据可视化</h2>
      </div>
      <div>
        <div class="form-item">
          <input type="text" v-model="userName" placeholder="请输入账号" />
        </div>
        <div class="form-item">
          <input
            :type="isPassword ? 'password' : 'text'"
            v-model="password"
            placeholder="请输入密码"
          />
          <img
            @click="isPassword = !isPassword"
            v-show="isPassword"
            class="show-pwd"
            src="../assets/img/no-pwd.svg"
            alt=""
          />
          <img
            @click="isPassword = !isPassword"
            v-show="!isPassword"
            class="show-pwd"
            src="../assets/img/pwd.svg"
            alt=""
          />
        </div>
        <button class="submit-btn" @click="handleSubmit">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
export default {
  name: "Login",
  components: {},
  data() {
    return {
      isPassword: true,
      userName: "",
      password: "",
    };
  },

  methods: {
    handleSubmit() {
      // console.log(this.userName, this.password);
      if (!this.userName) {
        Message.error('请输入账号');
        return false;
      }
      if (!this.password) {
        Message.error('请输入密码');
        return false;
      }
      let data = { userName: this.userName, password: this.password };
      this.$emit("onSubmit", data);
    },
  
  },
};
</script>
<style scoped>
.page-box {
  background-color: #0C0C2A;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.bg-img{
  /* flex: 1; */
  width: 48%;
  
}

.right {
  max-width: 480px;
  width: 48%;
  padding: 40px;
  border-radius: 10px;
  box-shadow: rgb(52, 75, 140) 0px 0px 25px 3px inset;
  box-sizing: border-box;
  margin-right: 50px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.title-logo {
  width: 60px;
}
.title h2 {
  margin: 0;
  font-size: 30px;
  white-space: nowrap;
  color: #888;
}
.form-item {
  height: 40px;
  width: 100%;
  margin-bottom: 18px;
  position: relative;
}
input {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 1px #dfdede inset;
  padding: 0 10px;
  box-sizing: border-box;
  background: #000;
    color: #888;
}
.show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
  width: 20px;
}
.submit-btn {
  width: 100%;
  height: 40px;
  background-color: rgb(83, 120, 244);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
