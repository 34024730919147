<template>
  <div class="module-box">
    <div style="flex: 0 1 25%">
      <dv-border-box-12 style="width: 100%; height:46vh" class="padding">
        <dv-loading v-if="sourceLoading">Loading...</dv-loading>
        <div class="radio-box source-box ">
          <el-radio-group v-model="sourceRadio" @input="handleRadio1" size="mini">
            <el-radio-button label="0">日榜</el-radio-button>
            <el-radio-button label="1">月榜</el-radio-button>
            <el-radio-button label="2">年榜</el-radio-button>
          </el-radio-group>
        </div>
        <div style="width: 100%;height:40vh;overflow-y: scroll;">
          <div id="source" style="width: 100%; height: 2000px;"></div>
        </div>
      </dv-border-box-12>
    </div>

    <div style="flex: 0 1 50%">
      <dv-border-box-12 style="width: 100%; height: 11vh;margin-bottom: 8px;">
        <div class="title-num-count">当日数/金额</div>
        <div class="main-top" style="width: 100%; height:10vh">
          <div class=" count-item " :class="active === '1' && 'active'" @click="handleClickNum('1')">
            <div class="header-count">
              {{ dayData.new_tally_count }}/¥{{ dayData.new_tally_money }}
            </div>
            <div class=" header-text">新签代账</div>
          </div>
          <div class=" count-item" :class="active === '2' && 'active'" @click="handleClickNum('2')">
            <div class="header-count">
              {{ dayData.renew_tally_count }}/¥{{ dayData.renew_tally_money }}
            </div>
            <div class=" header-text">续签代账</div>
          </div>
          <div class=" count-item" :class="active === '3' && 'active'" @click="handleClickNum('3')">
            <div class="header-count">
              {{ dayData.other_count }}/¥{{ dayData.other_money }}
            </div>
            <div class=" header-text">其它</div>
          </div>

          <div class=" count-item" :class="active === '4' && 'active'" @click="handleClickNum('4')">
            <div class="header-count">
              {{ dayData.zjs_count }}/¥{{ dayData.zjs_money }}
            </div>
            <div class=" header-text">转介绍</div>
          </div>

          <div class=" count-item" :class="active === '5' && 'active'" @click="handleClickNum('5')">
            <div class="header-count">
              {{ dayData.seperated_count }}/¥{{ dayData.seperated_money }}
            </div>
            <div class=" header-text">名单</div>
          </div>
        </div>
      </dv-border-box-12>

      <dv-border-box-12 class="padding " style="width: 100%; height:34vh">

        <dv-loading v-if="contractListLoading">Loading...</dv-loading>
        <div class=" main-box" style="width: 100%; height:33vh">
          <div style="flex:1;height:33vh ">
            <div class="ul" v-if="active=='5'">
              <div class="li" v-for="item in guest_list" :key="item.id">{{ item.id }}<span class="name">{{
                item.name }} </span> {{ item.created_at }}
                <span class="money">¥{{ item.money }}
                 </span> 
                 {{ item.type=='1'?'起名核名':item.type=='2'?'精准客资':item.type=='3'?'客资录入':item.type=='4'?'导入':'赠送' }} 
                 {{ item.city }}  {{  item.source}} 分给:{{ item.user_name }}</div>
            </div>
            <div class="ul" v-else>
              <div class="li" v-for="item in contract_list" :key="item.id">{{ item.format_id }}<span class="name">{{
                item.created_name }} </span> {{ item.created_at }}
                {{ item.company }} <span class="money">¥{{ item.money }} </span> {{ item.source_name }} </div>
            </div>
          </div>


        </div>

      </dv-border-box-12>
    </div>

    <div style="flex: 0 1 25%">


      <dv-border-box-1 style="width: 100%; height:46vh;">
        <div class="radio-box">
          <el-radio-group v-model="radio" @input="handleRadio" size="mini">
            <!-- ranking -->
            <el-radio-button label="0">日榜</el-radio-button>
            <el-radio-button label="1">月榜</el-radio-button>
            <el-radio-button label="2">年榜</el-radio-button>
            <el-radio-button label="3">转介绍月榜</el-radio-button>
            <el-radio-button label="4">转介绍年榜</el-radio-button>
          </el-radio-group>
        </div>
        <div class="ranking-content">

          <div class="body">
            <!-- <dv-border-box-10 style="box-shadow: #1f2f5d 0px 0px 25px 3px inset">
              <div class="item-box">
                <span class="index pai"></span>
                <div class="row-item flex2">
                  姓 名----公司名称
                </div>
                <div class=" row-item">代账数/额</div>
                <div class=" row-item">总计数/额</div>
              </div>
            </dv-border-box-10> -->

            <div class="li-box">
              <dv-loading v-if="rankingLoading">Loading...</dv-loading>

              <div v-for="(item, index) in rankingList" :key="item.id">
                <div class="item-box" :class="index == 0 ? 'one' : index == 1 ? 'two' : index == 2 ? 'three' : ''">
                  <span class="index "
                    :class="index == 0 ? 'one-icon' : index == 1 ? 'two-icon' : index == 2 ? 'three-icon' : ''">{{ index
                      + 1 }}</span>
                  <div class="row-item flex2" :title="item?.branch_name">
                    {{ item.name }} {{ item?.branch_name }}
                  </div>
                  <div class="total row-item color-count"> {{ item.tally_count }}/{{ item.tally_money }}</div>
                  <div class="total row-item"> {{ item.all_count }}/{{ item.all_money }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </div>
  </div>
</template>

<script>



import * as echarts from "echarts";
import util from '../utils/util.js'
//第一步：import引入
import errImg from '../assets/img/default.png'
export default {
  components: {

  },
  props: {

    contract_list: {
      type: Array,
      default: []
    },
 
    ranking: {
      type: Array,
      default: []
    },
    source_ranking: {
      type: Array,
      default: []
    },
    dayData: {
      type: Object,
      default: {}
    },
    zjsRanking: {
      type: Array,
      default: []
    },
    guest_list: {
      type: Array,
      default: ()=>[]
    },
    branchId:{
      type: [String,Number],
      default: ''
    }

  },
  data() {
    return {
      errImg,
      radio: 0,
      sourceRadio: null,
      rankingLoading: true,
      contractListLoading: true,
      sourceLoading: true,
      sourceName: [],
      sourceData: [],
      active: '',
    };
  },
  mounted() {
    this.handleRadio(this.radio)
  },
  methods: {
    handleClickNum(value) {
      this.active = value;
      this.$emit('changeT', value)
      console.log(value, 'value');
    },
    handleRadio1(e) {
      console.log(e);
      let begin_at = util.getNowTime();
      let end_at = util.getNowTime();

      if (e == 1) {

        begin_at = util.getFirstDay();
        end_at = util.getLastDay()
      } else if (e == 2) {

        begin_at = util.getYearFirstDay();
        end_at = util.getYearLastDay()
      }
      let data = {
        begin_at: util.formatTime(util.sTime(begin_at)),
        end_at: util.formatTime(util.eTime(end_at))
      }
      this.$emit('changeSourceTime', data)
    },
    handleRadio(e) {
      console.log(e);
      let begin_at = util.getNowTime();
      let end_at = util.getNowTime();

      if (e == 1 || e == 3) {
        begin_at = util.getFirstDay();
        end_at = util.getLastDay()
      } else if (e == 2 || e == 4) {
        begin_at = util.getYearFirstDay();
        end_at = util.getYearLastDay()
      }
      let data = {
        begin_at: util.formatTime(util.sTime(begin_at)),
        end_at: util.formatTime(util.eTime(end_at))
      }
      this.$emit('changeTime', data, e)
    },
    echartSource() {
      const chartDom = document.getElementById("source");
      const myChart = echarts.init(chartDom);
      var option;
      var data = [...this.source_ranking]
      function contains(arr, dst) {
        var i = arr.length;
        while ((i -= 1)) {
          if (arr[i] == dst) {
            return i;
          }
        }
        return false;
      }
      var attackSourcesColor = [
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#EB3B5A" },
          { offset: 1, color: "#FE9C5A" }
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#FA8231" },
          { offset: 1, color: "#FFD14C" }
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#2fb362" },
          { offset: 1, color: "#FFEE96" }
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#395CFE" },
          { offset: 1, color: "#2EC7CF" }
        ])
      ];
      var attackSourcesColor1 = [
        "#EB3B5A",
        "#FA8231",
        "#2fb362",
        "#3860FC",
        "#1089E7",
        "#F57474",
        "#56D0E3",
        "#1089E7",
        "#F57474",
        "#1089E7",
        "#F57474",
        "#F57474"
      ];
      var attaData = [];
      var attaName = [];
      var total_guest_count = [];
      data.forEach((it, index) => {
        attaData[index] = it.total_count;
        attaName[index] = `${it.source_name}  ${it.total_guest_count}`
        // total_guest_count[index] = it.total_guest_count;
      });
      var salvProMax = []; //背景按最大值
      for (let i = 0; i < attaData.length; i++) {
        salvProMax.push(attaData[0]);
      }
      function attackSourcesDataFmt(sData) {
        var sss = [];
        sData.forEach(function (item, i) {
          let itemStyle = {
            color: i > 3 ? attackSourcesColor[3] : attackSourcesColor[i]
          };
          sss.push({
            value: item,
            itemStyle: itemStyle
          });
        });
        return sss;
      }

      var option = {
        tooltip: {

          textStyle: {
            fontSize: 16
          }
        },
        grid: {
          left: "1%",
          right: "1%",
          width: "96%",
          bottom: "6px",
          top: 0,
          containLabel: true
        },
        xAxis: {
          type: "value",

          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: [
          {
            type: "category",
            inverse: true,

            axisLine: {
              show: false
            },
            axisTick: {
              show: false,
              interval: 1
            },

            axisPointer: {
              label: {
                show: true,
                //margin: 30
              }
            },
            pdaaing: [5, 0, 0, 0],
            postion: "right",
            data: attaName,
            axisLabel: {
              margin: 30,
              fontSize: 12,
              align: "left",
              padding: [2, 0, 0, 0],
              color: "#000",
              rich: {
                nt1: {
                  color: "#fff",
                  backgroundColor: attackSourcesColor1[0],
                  width: 13,
                  height: 13,
                  fontSize: 10,
                  align: "center",
                  borderRadius: 100,
                  lineHeight: "5",
                  padding: [0, 1, 2, 1]
                  // padding:[0,0,2,0],
                },
                nt2: {
                  color: "#fff",
                  backgroundColor: attackSourcesColor1[1],
                  width: 13,
                  height: 13,
                  fontSize: 10,
                  align: "center",
                  borderRadius: 100,
                  padding: [0, 1, 2, 1]
                },
                nt3: {
                  color: "#fff",
                  backgroundColor: attackSourcesColor1[2],
                  width: 13,
                  height: 13,
                  fontSize: 10,
                  align: "center",
                  borderRadius: 100,
                  padding: [0, 1, 2, 1]
                },
                nt: {
                  color: "#fff",
                  backgroundColor: 'rgb(52, 75, 140)',
                  width: 13,
                  height: 13,
                  fontSize: 10,
                  align: "center",
                  lineHeight: 3,
                  borderRadius: 100,
                  padding: [0, 1, 2, 1],
                  lineHeight: 5
                }
              },
              formatter: function (value, index) {
                index = contains(attaName, value) + 1;
                if (index - 1 < 3) {
                  return ["{nt" + index + "|" + index + "}"].join("\n");
                } else {
                  return ["{nt|" + index + "}"].join("\n");
                }
              }
            }
          },
          {
            type: "category",

            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#a0b0d0",
                fontSize: "12"
              }
            },
            data: attackSourcesDataFmt(attaName)
          },
          // {
          //   type: "category",
          //   inverse: true,
          //   axisTick: "none",
          //   axisLine: "none",
          //   show: true,

          //   axisLabel: {
          //     textStyle: {
          //       color: "#f00",
          //       fontSize: "12"
          //     }
          //   },
          //   data: attackSourcesDataFmt(total_guest_count)
          // },

        ],
        series: [
          {
            zlevel: 1,
            type: "bar",
            barWidth: "10px",
            animationDuration: 1500,
            data: attackSourcesDataFmt(attaData),
            align: "center",
            itemStyle: {
              normal: {
                barBorderRadius: 10
              }
            },
            label: {
              show: true,
              fontSize: 12,
              color: "#fff",
              textBorderWidth: 2,
              padding: [2, 0, 0, 0]
            }
          },
          {

            type: "bar",
            barWidth: 10,
            barGap: "-100%",
            margin: "20",
            data: salvProMax,
            // data: [90,100,100,100,100,100,100,100,100,100],
            textStyle: {
              //图例文字的样式
              fontSize: 10,
              color: "#fff"
            },
            itemStyle: {
              normal: {
                color: "#05325F",
                // color: "#f00",

                // width:"100%",
                fontSize: 12,
                barBorderRadius: 30
              },
            }
          }
        ]
      };
      option && myChart.setOption(option);
    },

  },
  computed: {
    rankingList() {
      return this.radio == 3 || this.radio == 4 ? this.zjsRanking : this.ranking
    },
   

  },
  watch: {
 
    ranking(newVal) {
      this.rankingLoading = false
    },
    contract_list(newVal) {
      this.contractListLoading = false
    },
    source_ranking(newVal) {
      this.sourceLoading = false
      this.echartSource()
    },
    branchId(newVal){
      this.active='';

    }

  },
};
</script>
<style  scoped>
.radio-box {
  padding-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;


}

.source-box {
  padding-top: 2px;

}

:deep .el-radio-group .el-radio-button__inner {
  background: inherit;
}

:deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: rgb(13 126 240 / 30%);
  color: #92a8c5;
}

:deep .el-radio-button--mini .el-radio-button__inner {
  padding: 6px 6px;
}


.ranking-content {
  padding: 2px 10px;
  font-size: 12px;
}

.head {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

}

.head-left {
  flex: 1;
  padding: 10px;
  text-align: center;
  box-shadow: 0 4px 16px 4px #5985de;
  border-radius: 10px 10px 0 0;
}

.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.head-center {
  padding-bottom: 26px;
}


.li-box {
  height: 38vh;
  overflow-y: scroll;
}

.body-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border: 1px solid #5985de;
  border-radius: 10px;
  margin-bottom: 10px;

}

.title-num-count {
  margin-left: 44%;
  position: absolute;
  top: -6px;
  left: 0;
  color: #92a8c5;
  background: #020f25;
  padding: 0 30px;

}






.total {
  font-size: 12px;
  font-weight: 900;
  color: rgb(232, 97, 29);
}

.color-count {
  color: #3fc7b6;
}

.color-or {
  color: rgb(232, 97, 29);
}

.img-icon {
  width: 38px;
  height: 38px;
  transform: rotate(30deg);
  margin-bottom: -16px;
}

.company {
  font-size: 12px;
}

.main-box {
  display: flex;
  font-size: 12px;

}

.ul {
  height: 31vh;
  overflow-y: scroll;

}

.li {

  padding: 2px 10px;
  color: #5985de;
  /* border: 1px solid #5985de; */
  border-bottom: 1px solid #363943;
  /* box-shadow: 0 4px 16px 4px #5985de; */
  border-radius: 6px;
  margin-bottom: 4px;

}



.box-title {
  position: absolute;
  padding: 2px;
}

.row-item {
  flex: 1;
}

.flex2 {
  flex: 2.2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



.item-box {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 2px;
  box-shadow: rgb(52, 75, 140) 0px 0px 25px 3px inset;
  border-radius: 8px;
  color: #9297c5;
}

.one {
  box-shadow: #91362f 0px 0px 25px 3px inset;
}

.two {
  box-shadow: #69562c 0px 0px 25px 3px inset;

}

.three {
  box-shadow: #0d522a 0px 0px 25px 3px inset;

}

.name {
  color: #a0b0d0;
  margin: 0 6px;

}

.money {
  color: #ba8363;
  margin: 0 6px;
}

.index {
  width: 20px;
  text-align: center;
  line-height: 16px;
  /* margin-right: 4px; */
  /* margin-right: 10px; */
}

.pai {
  width: 20px;
  height: 20px;
  display: inline-block;
  z-index: 2;
  background-image: url('../assets/img/zuan.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.one-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  z-index: 2;
  background-image: url('../assets/img/pai.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.two-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  z-index: 2;
  background-image: url('../assets/img/pai1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.three-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  z-index: 2;
  background-image: url('../assets/img/pai2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

/* .one-icon::after{
content: '1';
} */

.main-top {
  display: flex;
  padding-top: 18px;

}

.count-item {
  flex: 1;
  height: 7vh;
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgb(52, 75, 140) 0px 0px 25px 3px inset;
  border-radius: 6px;
  color: #9297c5;
  cursor: pointer;
  font-size: 12px;

}

.active {
  box-sizing: border-box;
  border-bottom: 2px solid #57b1f1;
}
</style>
