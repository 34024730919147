import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import './assets/css/index.css'
// import * as echarts from 'echarts';
import { Carousel,
  CarouselItem,Select,
  Option, RadioGroup,RadioButton} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// Vue.prototype.$echarts = echarts;


Vue.config.productionTip = false
Vue.use(dataV);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
