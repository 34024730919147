
function sTime(e) {
	let today = new Date(e);
	let time = today.setHours(0, 0, 0, 0) / 1000;
	return time;
}

function eTime(e) {
	let today = new Date(e);
	let time = today.setHours(23, 59, 59, 0) / 1000;
	return time;
}

function timetamp(time) {
	var date = new Date();
	date.setFullYear(time.substring(0, 4)); //年

	date.setMonth(time.substring(5, 7) - 1); //月

	date.setDate(time.substring(8, 10)); //日

	date.setHours(time.substring(11, 13)); //时

	date.setMinutes(time.substring(14, 16)); //分

	date.setSeconds(time.substring(17, 19)); //秒

	return Date.parse(date) / 1000;
}

function paramsValidate(params) {
	let flag = true;

	for (var key in params) {
		if (params[key] != '0' && !params[key]) {
			return false;
		}
	}

	return flag;
} // 获取当天日期

function getNowTime() {
	var date = new Date();
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();
	var time = year + '-' + addZero(month) + '-' + addZero(day);
	return time;
}

function addZero(s) {
	return s < 10 ? '0' + s : s;
}

function getFirstDay() {
	var y = new Date().getFullYear(); //获取年份

	var m = new Date().getMonth() + 1; //获取月份

	var d = '01';
	m = m < 10 ? '0' + m : m; //月份补 0

	return [y, m, d].join('-');
}

function getUpMonthFirstDay() {
	const currentDate = new Date();
	const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
	const time = new Date(lastMonth).getTime()
	// 时间戳转为正常时间的公共方法，当然你也可以加上小时、分、秒
	const date = new Date(time)
	const Y = date.getFullYear()
	let M = date.getMonth() + 1
	let D = '01';
	M = M < 10 ? '0' + M : M; //月份补 0
	return `${Y}-${M}-${D}`
}

function getUpMonthLastDay() {
	const currentDate = new Date();
	const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
	const time = new Date(lastMonth).getTime()
	// 时间戳转为正常时间的公共方法，当然你也可以加上小时、分、秒
	const date = new Date(time)
	const Y = date.getFullYear()
	let M = date.getMonth() + 1
	let D = new Date(Y, M, 0).getDate(); //获取当月最后一日
	M = M < 10 ? '0' + M : M; //月份补 0
	D = D < 10 ? '0' + D : D; //日数补 0
	return `${Y}-${M}-${D}`
}

function getLastDay() {
	var y = new Date().getFullYear(); //获取年份

	var m = new Date().getMonth() + 1; //获取月份

	var d = new Date(y, m, 0).getDate(); //获取当月最后一日

	m = m < 10 ? '0' + m : m; //月份补 0

	d = d < 10 ? '0' + d : d; //日数补 0

	return [y, m, d].join('-');
}

function getYearFirstDay() {
	var firstDay = new Date();
	firstDay.setDate(1);
	firstDay.setMonth(0);
	var y = firstDay.getFullYear(); //获取年份

	var m = firstDay.getMonth() + 1; //获取月份

	var d = firstDay.getDate(); //获取当月最后一日

	m = m < 10 ? '0' + m : m; //月份补 0

	d = d < 10 ? '0' + d : d; //日数补 0

	return [y, m, d].join('-');
}

function getYearLastDay() {
	const currentYear = new Date().getFullYear();
	return currentYear+"-12-31"
}

function formatTime(timestampa) {
	var date = new Date(timestampa.length > 10 ? timestampa : timestampa * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

	var Y = date.getFullYear();
	var M = date.getMonth() + 1;
	M < 10 ? (M = '0' + M) : M;
	var D = date.getDate();
	D < 10 ? (D = '0' + D) : D;
	var h = date.getHours();
	h < 10 ? (h = '0' + h) : h;
	var m = date.getMinutes();
	m < 10 ? (m = '0' + m) : m;
	var s = date.getSeconds();
	s < 10 ? (s = '0' + s) : s;
	return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
}

 
 function getLastDayOfMonth(year, month) {
  return new Date(year, month, 0).getDate();
}





module.exports = {

	sTime,
	eTime,
	timetamp,
	paramsValidate,
	getNowTime,
	getFirstDay,
	getLastDay,
	getYearFirstDay,
	getYearLastDay,
	formatTime,
	getUpMonthFirstDay,
	getUpMonthLastDay,
	getLastDayOfMonth

};